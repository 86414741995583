/*
* Try to keep in the same order as
* the form so it's easy to match up
*/

export const fieldKeys = [
    'show_id',
    'show_type',
    'specialist_id',
    'device_survey_guid',
    'start_time',

    // Non FFS Questions
    'why_are_you_here',
    'shows_attended',

    //Person Info
    'first_name',
    'last_name',
    // 'business_name',
    'address1',
    'address2',
    'city',
    'state',
    'zip_code',

    //Contact Info
    'phone',
    'email',
    'gender',
    'age_bracket',
    'ethnicity',

    //opt-ins
    'email_opt_in',
    'accepts_sms',

    //VOI
    'vehicles_of_interest',

    //Current Vehicle
    'vehicle_driven_most_make_id',

    //Acquire Next
    'in_market_timing',
    // Personal Or Business
    // 'personal_or_business',
    // 'industry',
    'minorName1',
    'minorName2',
    'minorName3',
    'signature',
    'minor_signature',
    'has_waiver',
    'has_minor_waiver',
    'waiver_agreement',

    'preferred_language',
    'highest_level_education',


];

export default {
    fieldKeys,
    defaultValues: {
        show_id: null,
        specialist_id: null,
        device_survey_guid: '',
        start_time: null,
        show_type: {},
        minorName1: '',
        minorName2: '',
        minorName3: '',
        signature: '',
        minor_signature: '',
        has_waiver: false,
        has_minor_waiver: false,
        // waiver_agreement: null,

        //NON FFS Questions
        why_are_you_here: '',
        shows_attended: '',
        preferred_language: '',
        highest_level_education: '',
        ethnicity: '',

        //Person Info
        first_name: '',
        last_name: '',
        business_name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip_code: '',

        //Contact Info
        phone: '',
        email: '',
        gender: '',
        age_bracket: '',

        //opt-ins
        email_opt_in: '',
        accepts_sms: 0,

        //VOI
        vehicles_of_interest: [],

        //Current Vehicle
        vehicle_driven_most_make_id: '',

        //Acquire Next
        in_market_timing: '',
        // Personal Or Business
        // personal_or_business: '',
        // industry: '',
    },
};

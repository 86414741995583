import uuid from 'uuid';

export function submitForm(fields) {
    return function(dispatch, API, getState) {
        const { language } = getState().app;

        const minorCustomData = ['minorName1','minorName2', 'minorName3']
            .reduce((minors, minor) => {
                if(fields[minor]) {
                    minors[minor] = fields[minor]
                }
                return minors
            }, {});

        let data = {
            ...genericSurveyData({...fields, language}),
            ...minorCustomData,
        };

        return API.survey.submit(data)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch(error => {
                return Promise.reject(error)
            })

    }
}

export function validateEmail(email) {
    return function(dispatch, API, getState) {
        return API.survey.validateEmail(email)
            .then(response => {
                return Promise.resolve(response.data)
            })
            .catch(error => {
                return Promise.reject(error)
            })
    }
}

function genericSurveyData(survey) {
    let formattedPhone = survey.phone;

    if (survey.phone && !/^\\d{3}-\\d{3}-\\d{4}$/.test(survey.phone)){
        formattedPhone = formatPhoneNumber(survey.phone)
    }
    const customData = {
        device_name: survey.device_name,
        minor_name_1: survey.minorName1 || null,
        minor_name_2: survey.minorName2 || null,
        minor_name_3: survey.minorName3 || null,
    };

    return {
        /* non-UI fields */
        show_id: survey.show_id,
        end_date: survey.end_date,
        specialist_id: survey.specialist_id,
        team_id: survey.team_id,
        survey_date: new Date(),
        device_survey_guid: survey.device_survey_guid,
        device_id: window.navigator.userAgent,
        survey_type: survey.survey_type,
        app_version: PKG_VERSION,
        abandoned: survey.abandoned || 0,
        start_time: survey.start_time,
        end_time: new Date(),
        
        /* custom_data // NON FFS questions */
        custom_data: JSON.stringify({
            device_name: survey.device_name,
            why_are_you_here: survey.why_are_you_here,
            shows_attended: survey.shows_attended,
            preferred_language: survey.preferred_language,
            highest_level_education: survey.highest_level_education,
            ethnicity: survey.ethnicity,
            ...customData,
        }),

        /* personal */
        first_name: survey.first_name,
        last_name: survey.last_name,
        business_name: survey.business_name || null,
        address1: survey.address1,
        address2: survey.address2 || null,
        city: survey.city,
        state: survey.state,
        country_code: survey.country_code || 'USA',
        zip_code: survey.zip_code,

        /* contact */
        phone: formattedPhone || null,
        email: survey.email,
        email_opt_in: survey.email_opt_in,
        accepts_sms: 0,
        gender: survey.gender,
        age_bracket: survey.age_bracket,

        /* other */
        vehicle_driven_most_make_id: survey.vehicle_driven_most_make_id || null,
        in_market_timing: survey.in_market_timing || null,
        personal_or_business: survey.personal_or_business || null,
        language: survey.language,
        industry: survey.industry || null,

        /* signature */
        waiver_type: survey.waiver_type || 'microsite',
        signature: survey.signature || null,
        minor_signature: survey.minor_signature || null,

        /* vehicles of interest */
        vehicles_of_interest: survey.vehicles_of_interest || [],
    }
}

function formatPhoneNumber(phone) {
    let cleaned = ('' + phone).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return match[1] + '-' + match[2] + '-' + match[3]
    }
    return null
}


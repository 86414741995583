import ApiGroup from './ApiGroup'

export default class formAPI extends ApiGroup {
    constructor() {
        super({
            name: 'survey',
        });
    }
    
    validateEmail = (email) => {
        return this.get(`/validate/email`,{email},{
            headers: {
                'Authorization': process.env.SHOWTRACKER_ACCESS_KEY,
            },
        })
    };

    submit = (data) => (
        this.post({
            endpoint: `/insertMicrosite`,
            data,
        },
        {
            headers: {
                'Authorization': process.env.SHOWTRACKER_ACCESS_KEY,
            },
        })
    );
}
